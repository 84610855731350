import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IframeService {
  openInIFrameSig = signal<boolean>(false)
  constructor() { }

  updateIFrameStatus(value: boolean): void {
    this.openInIFrameSig.set(value)
  }
}
